import React from 'react';
import { Typography, Container, styled, Link } from '@mui/material';
import { graphql, StaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';
import { SEO } from '../../components/Seo';
import Layout from '../../components/Layout';
import FeaturedCard from '../../components/FeaturedCard';
import ContentContainer from '../../styles/content-container';
import Block from '../../img/block.svg';
import theme from '../../styles/theme';

const LightContainer = styled(Container)(() => ({
  padding: '40px',
  backgroundColor: theme.palette.paladin.card,
  border: '2px solid',
  borderColor: theme.palette.paladin.navBar,
  borderRadius: '16px',
  marginLeft: '0',
  marginRight: '0',
  marginBottom: '5%',
}));

const UnderlinedContainer = styled(Link)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  marginLeft: '0',
  marginRight: '0',
  borderBottom: `1px solid ${theme.palette.paladin.navBar}`,
  marginBottom: '5%',
}));

const text = {
  defiOne: 'How do we ensure DeFi stays resilient?',
  defiTwo:
    'More governors. More participation. Better transparency around influnce and control. These are core to the Paladin mission because there’s more to DeFi than just speculation.',
  manifesto: 'Manifesto for a new wave of corporate activism',
};

export default function Vault() {
  return (
    <Layout>
      <ContentContainer
        disableGutters
        sx={{ marginBottom: '5%', [theme.breakpoints.down('sm')]: { width: '100% !important' } }}
      >
        <StaticImage
          src="../../img/knowledge-vault-hero.svg"
          alt="vault hero"
          style={{ width: '100%' }}
          placeholder="blurred"
          loading="eager"
        />
      </ContentContainer>
      <ContentContainer
        disableGutters
        sx={{
          [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '32px',
            marginBottom: '32px',
          },
          [theme.breakpoints.down('sm')]: { display: 'block' },
        }}
      >
        <Container
          disableGutters
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row', 'row', 'row', 'row'],
            justifyContent: 'space-between',
            gap: '16px',
            marginBottom: '24px',
          }}
        >
          <LightContainer
            disableGutters
            sx={{
              maxWidth: ['100% !important', '48% !important', '48% !important', '45% !important', '45% !important'],
            }}
          >
            <Typography variant="h2">{text.defiOne}</Typography>
            <Typography variant="body2" sx={{ marginTop: '6%' }}>
              {text.defiTwo}
            </Typography>
          </LightContainer>
          <UnderlinedContainer
            disableGutters
            underline="none"
            href="/blog/manifesto-for-a-new-wave-of-corporate-activism/"
            sx={{
              maxWidth: ['100% !important', '48% !important', '48% !important', '48% !important', '48% !important'],
            }}
          >
            <StaticImage
              css={css`
                width: 100%;
                @media (min-width: 600px) and (max-width: 900px) {
                  object-fit: cover;
                  height: 50%;
                },
              `}
              src="../../img/revolution.svg"
              alt="revolutionary war"
            />
            <Typography variant="h3" sx={{ marginTop: '3%', marginBottom: '1.5%' }}>
              {text.manifesto}
              <StaticImage
                src="../../img/arrowLink.svg"
                alt="link arrow"
                style={{ marginLeft: '8px', verticalAlign: 'middle' }}
                placeholder="blurred"
                loading="lazy"
              />
            </Typography>
          </UnderlinedContainer>
        </Container>

        <Container disableGutters sx={{ display: 'none', flexDirection: ['column', 'row', 'row', 'row', 'row'] }}>
          <Link
            href="/vault"
            sx={{
              borderRadius: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: '581px',
              [theme.breakpoints.down('sm')]: { marginBottom: '32px' },
              [theme.breakpoints.up('sm')]: { marginRight: '22px' },
            }}
          >
            <StaticImage
              src="../../img/paladinLending.png"
              alt="Paladin Lending Background"
              imgStyle={{ width: '100%', height: 'auto' }}
              placeholder="blurred"
              loading="lazy"
            />
          </Link>
          <Link
            href="/vault"
            sx={{
              borderRadius: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: '581px',
            }}
          >
            <StaticImage
              src="../../img/warden.png"
              alt="Warden Background"
              imgStyle={{ width: '100%', height: 'auto' }}
              placeholder="blurred"
              loading="lazy"
            />
          </Link>
        </Container>
      </ContentContainer>

      <StaticQuery
        query={graphql`
          query AllBlog {
            allMarkdownRemark(
              sort: { fields: [frontmatter___date], order: DESC }
              filter: { frontmatter: { collection: { eq: "blog" } } }
            ) {
              edges {
                node {
                  frontmatter {
                    tags
                    title
                    path
                    summary_text
                    summary_image
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <ContentContainer
            disableGutters
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              marginTop: '0',
              gap: ['16px', '0px', '0px', '0px', '0px'],
            }}
          >
            {data.allMarkdownRemark.edges
              .map((item) => item.node.frontmatter)
              .filter(({ tags }) => tags && tags.findIndex((tag) => tag === 'Vault') >= 0)
              .map((frontmatter) => (
                <FeaturedCard
                  src={frontmatter.summary_image ? frontmatter.summary_image : Block}
                  alt="Summary"
                  title={frontmatter.title}
                  summary={frontmatter.summary_text}
                  href={`/blog/${frontmatter.path}`}
                  sx={{ maxWidth: ['none', '48%!important', '31%!important', '31%!important', '31%!important'] }}
                  img_sx={{ [theme.breakpoints.up('md')]: { maxHeight: '180px', minHeight: '180px' } }}
                />
              ))}
          </ContentContainer>
        )}
      />
    </Layout>
  );
}

export const Head = () => <SEO />;
