import React from 'react';
import { Typography, Link, styled } from '@mui/material';
import ContentContainer from '../styles/content-container';

function FeaturedCard(props) {
  const { src, alt, title, summary, href, sx, img_sx } = props;
  return (
    <ContentContainer
      disableGutters
      sx={{
        margin: '0 0 48px 0',
        ...sx,
      }}
    >
      <Link href={!href ? '/' : href} underline="none">
        <Image src={src} alt={alt} sx={img_sx} />
        <Typography variant="h3" sx={{ marginBottom: '.5%', marginLeft: '1%' }}>
          {title}
        </Typography>
        <Typography variant="caption" sx={{ fontSize: '16px !important', marginLeft: '1%' }}>
          {summary ? summary : 'Summary Text Not Setup'}
        </Typography>
      </Link>
    </ContentContainer>
  );
}
export default FeaturedCard;

const Image = styled('img')(() => ({
  width: '100%',
  marginBottom: '3%',
  height: ['100px', '125px', '150px', '200px', '225px'],
  objectFit: 'cover',
}));
